import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { getRedemptionCode } from "../services/api/redemption";

const Redeem = () => {
  const [link, setRedemptionLink] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getRedemptionLink = async () => {
      try {
        const token = await getAccessTokenSilently();
        const redemption: any = await getRedemptionCode(token);
        setRedemptionLink(redemption.data.link);
      } catch (error: any) {
        let message =
          error.response && error.response.data
            ? error.response.data
            : error.message;
        setErrorMessage(message);
      }
    };
    getRedemptionLink();
  }, [getAccessTokenSilently]);

  return (
    <div className="App">
      <header className="App-header">
        <img src="logo.svg" className="App-logo" alt="logo" />
      </header>
      {errorMessage && <div className="alert">{errorMessage}</div>}
      <div className="App-ErrorMessage"></div>
      <div className="App-summary">
        The Employee Lifecycle Management (ELM) app from LeadingAgile includes
        information and assessments for all our people as well as Pod
        structures, client account details, and Communities of Practice.
      </div>
      <div className="App-instructions">
        <ol>
          <li>Be on the device</li>
          <li>Remove existing version if you were on TestFlight.</li>
          <li>Click the badge below.</li>
        </ol>
      </div>
      <div className="App-Download">
        {link && (
          <a href={link}>
            <img
              src="Download_on_the_App_Store_Badge.svg"
              alt="Download on the App Store"
            />
          </a>
        )}
      </div>
      <div className="App-coaching-images">
        <img src="people.jpg" alt="People" />
        <img src="filter.jpg" alt="Filters" />
        <img src="profile.jpg" alt="Profile" />
        <img src="kudos.jpg" alt="Kudos" />
        <img src="cops.jpg" alt="CoPs" />
      </div>
    </div>
  );
};

export default Redeem;
