import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Redeem from "./views/Redeem";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Redeem />
            </PrivateRoute>
          }
        ></Route>
      </Routes>
    </Router>
  );
};

const PrivateRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }

    const fn = async () => {
      await loginWithRedirect();
    };

    fn();
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  return isAuthenticated ? children : null;
};

export default App;
